import React from "react"
import Layout from "../components/layout"
import Image  from "../components/image"
import SEO from "../components/seo"
import ContactForm from "../components/contact"
import {Container,Row,Col,ListGroup} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleRight} from '@fortawesome/free-solid-svg-icons'


const electricDesign =()=>{
    return(
        <Layout>
            <SEO title="Electrical Design" />
            <div className="homeAuto">
                <Image alt="home automation" filename="circut-1.png" />
                <div className="electricalImage">
                    <h1 style={{fontWeight:"bold"}} className="protohead">ELECTRICAL DESIGN</h1>
                    <h3 className="crmheadpara">Ingenuity and Innovation for every client.</h3>
                    <p className="electriP" style={{fontSize:"20px"}}>Looking for leading-edge Electrical Design? You’ve come to the right place.</p>
                </div>
            </div>
            <Container>
                <div className="electricPara">Trace Width Technology Solutions specializes in superior circuit design and implementation. We are committed to providing our clients with complete solutions for their electronic product design needs.</div>
                <Row>
                    <Col sm={12} lg={6}><Image alt="circuit" filename="circute.png"  /></Col>
                    <Col sm={12} lg={6} className="manuPara1">
                        <p>In today’s competitive arena, time-to-market is very critical and hiring the right team is essential. With Trace Width on your side, you’ll benefit from specialized development, hands-on experience, and a communicative and reliable team with a proven track record for success in PCB and circuit design. Some of the key highlights of our electronics design include –</p>
                    </Col>
                </Row>
                <br/> <br/>
                <Row>
                    <Col sm={12} lg={6}>
                        <ListGroup variant="flush" className="listContent">
                            <ListGroup.Item><FontAwesomeIcon icon={faAngleRight} size="1x" style={{ fontSize:"20px"}}/> &nbsp;&nbsp;Schematic Design and Simulation Modeling</ListGroup.Item>
                            <ListGroup.Item><FontAwesomeIcon icon={faAngleRight} size="1x" style={{ fontSize:"20px"}}/> &nbsp;&nbsp;Green Eco-Friendly designs</ListGroup.Item>
                            <ListGroup.Item><FontAwesomeIcon icon={faAngleRight} size="1x" style={{ fontSize:"20px"}}/> &nbsp;&nbsp;High Speed and Multilayer Circuit Design</ListGroup.Item>
                            <ListGroup.Item><FontAwesomeIcon icon={faAngleRight} size="1x" style={{ fontSize:"20px"}}/> &nbsp;&nbsp;Bill of Material Specifications</ListGroup.Item>
                            <ListGroup.Item><FontAwesomeIcon icon={faAngleRight} size="1x" style={{ fontSize:"20px"}}/> &nbsp;&nbsp;Circuit Board CAD Layout</ListGroup.Item>
                            <ListGroup.Item><FontAwesomeIcon icon={faAngleRight} size="1x" style={{ fontSize:"20px"}}/> &nbsp;&nbsp;Full Functional Testing and Fixture Development</ListGroup.Item>
                            <ListGroup.Item><FontAwesomeIcon icon={faAngleRight} size="1x" style={{ fontSize:"20px"}}/> &nbsp;&nbsp;Firmware and Algorithm Development and Programming</ListGroup.Item>
                            <ListGroup.Item></ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col sm={12} lg={6} className="manuPara1">
                        <p>Trace Width offers a complete selection of design services from concept to finished product. We specialize in providing effective PCB services to utilize in your product assembly. When your company needs product prototyping, you can count on our Design Engineering Team to deliver high quality PCB assemblies on time at more than competitive prices. Our exclusive vantage point of custom circuit board assemblies and PCB requirements incorporates a deep understanding of the manufacturing process while befitting to your industry’s quality specifications. With large-scale prototype PCB fabrication capabilities, we will outpace your organization’s simple or complex electronics design prerequisites.</p>
                        <p>Trace Width has the expertise and equipment that is unparalleled to handle all your advanced circuit exigencies. Our advanced CAD programs, as well as profound simulation and analysis software, provides greater readiness, meticulousness, and quality control of the final product. Our goal is to be your privileged engineering backbone from the initial abstraction through the design phase to the actualization of your custom printed circuit board products.</p>
                    </Col>
                            
                </Row>
            </Container>
            <div className="headerForm"> 
               
               <br/>
              <div className="homeCardrow5">Contact the team at Trace Width today to learn more about our professional Electrical Design solutions!</div>
              <ContactForm  />
             
          </div>  

        </Layout>
    )
}

export default electricDesign;